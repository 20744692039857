@import './_variables'
@import './_mixins'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
  overflow-x: hidden

body
  position: relative
  margin: 0
  font-family: 'Montserrat', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: $main-text-size

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

img
  position: relative
  max-height: 100%
  max-width: 100%

input[type=text],
button
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none
  -ms-progress-appearance: none

button:disabled,
button[disabled]
  cursor: not-allowed
  opacity: .8

.u-white-text
  color: $white

.u-black-text
  color: $black

.u-bold-text
  font-weight: 700

.u-giant-text,
.u-large-text,
.u-medium-text,
.u-small-text
  line-height: 1

.u-giant-text
  font-size: 50px
  font-weight: 800
  +breakpoint('<tablet')
    font-size: 36px

.u-large-text
  font-size: 46px
  font-weight: 700
  letter-spacing: 0.8px
  +breakpoint('<tablet')
    font-size: 36px

.u-medium-text
  font-size: 20px
  font-weight: 600

.u-small-text
  font-size: 18px
  font-weight: 600
  +breakpoint('<tablet')
    font-size: 22px

.paragraph-text
  margin-bottom: 2em
  line-height: 1.2em
  font-weight: 400
  +breakpoint('<tablet')
    max-width: 100%

.o-section-title
  margin-bottom: $large-space

.interactive-section
  +flexbox(center, flex-start, column, nowrap)
  padding: $large-space calc($large-space*2)
  +breakpoint('<tablet')
    padding: $medium-space

  &__content
    +flexbox(center, flex-start, row, nowrap)
    gap: $large-space
    max-width: 100%
    +breakpoint('<tablet')
      +flexbox(flex-start, flex-start, column, nowrap)
      gap: $medium-space

    .tab-triggers-list
      +flexbox(flex-start, stretch, column, nowrap)
      width: 360px
      gap: $small-space
      +breakpoint('<tablet')
        overflow-x: auto
        +flexbox(flex-start, center, row, nowrap)
        padding-right: $medium-space
        width: 100%

      .c-tab-button
        cursor: pointer
        padding: $small-space calc($medium-space/1.3)
        border: 1px solid $divider-color
        border-radius: $main-border-radius
        +flexbox(space-between, center, row, nowrap)
        gap: $small-space
        box-shadow: none
        transition: background-color 250ms linear, color 250ms linear, box-shadow 100ms linear

        .button-text
          white-space: nowrap

        &__icon
          color: $black
          padding: $small-space + 2
          border-radius: 50%
          background-color: transparent
          box-shadow: none
          transition: background-color 250ms linear, color 250ms linear, box-shadow 250ms linear

          .icon
            +flexbox(center, center, row, nowrap)
            +square($small-space + 6)

        &__arrow-icon
          padding: $small-space + 2
          +breakpoint('<tablet')
            transform: rotateZ(90deg)

        &:hover
          color: $white
          background-color: lighten($blue, 25%)

      .c-active-tab
        color: $white
        background-color: lighten($blue, 25%)
        box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.11)

      .c-active-tab > .c-tab-button__icon,
      .c-tab-button:hover > .c-tab-button__icon
        background-color: lighten($blue, 10%)
        color: $white
        box-shadow: 0 3px 4px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.23)

.special-container
  position: relative
  width: 100%
  height: 480px
  background-repeat: no-repeat
  background-size: cover
  +breakpoint('<tablet')
    height: auto

  &__content
    z-index: 1

.o-dark-cover
  z-index: 0
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(0, 0, 0, .45)

.o-pep-logo-container
  +square(150px)

.smartspeaker-container,
.ingredient-section
  background-color: $black

.ingredient-section
  background-image: url('../resources/images/dishes.png')
  background-position: center center
  +flexbox(center, center, row, nowrap)
  +breakpoint('<tablet')
    flex-direction: column

.ingredient-section .special-container__content
  +flexbox(center, center, row, nowrap)
  gap: calc($large-space * 2)
  +breakpoint('<tablet')
    flex-direction: column
    padding: $large-space
    gap: $medium-space

  &__element > p:not(:first-child)
    margin-bottom: 0

  &__element > p:not(:last-child)
    margin-top: 0

  &__element:last-of-type
    max-width: 43%
    +breakpoint('<tablet')
      max-width: 95%

.testimony-section
  +flexbox(center, center, column, nowrap)
  +breakpoint('<tablet')
    padding: $large-space $medium-space
    text-align: center

.o-logo-list
  +flexbox(center, center, row, wrap)
  gap: $medium-space

  &__element
    +size(100px, 200px)

.smartspeaker-container
  background-image: url('../resources/images/smart-speaker.png')
  background-position: center 0
  display: grid
  grid-template-columns: repeat(2, 1fr)
  +breakpoint('<tablet')
    grid-template-columns: repeat(1, 1fr)

  .special-container__content
    padding: $medium-space $large-space
    +flexbox(center, center, column, nowrap)

.o-send-button,
.c-text-input
  padding: calc($medium-space/2) $medium-space calc($medium-space/2) calc($medium-space/2)
  border: 1.65px solid $divider-color
  border-radius: calc($main-border-radius*1.5)
  letter-spacing: 0.3px
  line-height: 1
  font-size: 16px

.c-text-input
  font-weight: 600

.o-send-button
  cursor: pointer
  font-weight: 800
  color: $white
  background-color: lighten($red, 10%)

.o-input-container
  margin: 0 auto $large-space
  position: relative
  +flexbox(center, stretch, column, nowrap)
  gap: $small-space
  width: 800px
  +breakpoint('<tablet')
    width: 90%

.o-input-grid
  display: grid
  grid-template-columns: repeat(2, auto)
  grid-template-rows: repeat(2, auto)
  gap: $small-space
  +breakpoint('<phone')
    grid-template-columns: repeat(1, auto)
    grid-template-rows: repeat(4, auto)

.o-checkbox-container
  +flexbox(flex-start, center, row, nowrap)

.o-carousel-element
  +flexbox(center, center, column, nowrap)
  position: relative
  height: 95vh
  width: 100%

.first-carousel-image
  background-image: url('../resources/images/first_slide.jpg')

.second-carousel-image
  background-image: url('../resources/images/second_slide.png')

.third-carousel-image
  background-image: url('../resources/images/third_slide.jpg')

.fourth-carousel-image
  background-image: url('../resources/images/fourth_slide.png')

.o-carousel-image,
.o-carousel-text-wrapper
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

.o-carousel-image
  z-index: 0
  background-size: cover
  +breakpoint('<tablet')
    background-position: center 0

.o-carousel-text-wrapper
  z-index: 2
  +flexbox(center, center, column, nowrap)
  color: $white
  padding: $large-space calc($large-space*2)
  +breakpoint('<tablet')
    padding: calc($large-space*2) $large-space

.o-carousel-text-wrapper-left,
.o-carousel-text-wrapper-right
  +breakpoint('<tablet')
    align-items: center !important

.o-carousel-text-wrapper-left
  align-items: flex-start

  .u-slide-title,
  .o-slide-subtitle
    text-align: left

.o-carousel-text-wrapper-right
  align-items: flex-end

  .u-slide-title,
  .o-slide-subtitle
    text-align: right

.u-slide-title
  font-size: 7em
  font-weight: 800
  margin: 0
  +breakpoint('<tablet')
    font-size: 5em
    text-align: center !important
    margin-bottom: 1em
  +breakpoint('<phone')
    font-size: 3.2em

.o-slide-subtitle
  position: relative
  width: 70%
  +breakpoint('<tablet')
    text-align: center !important
    width: 90%
    margin: 0 !important
